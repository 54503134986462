<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">报名管理</a>
                    <i>></i>
                    <a href="javascript:;">培训报名列表</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">学员管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">修改学员信息</a>
                </span>

            </div>
            <div class="framePage-body">
                <div class="framePage-body-inner">
                    <div class="framePage-body-inner-item">
                        <h3 v-if="type=='view'" class="title">培训信息</h3>
                        <h3 v-else class="title">考试信息</h3>
                        <div class="examInfo" style="padding-top:1px;">
                            <div class="personInifo-wrap">
                                <div class="label">培训名称:</div>
                                <div class="value">{{examInfo.projectName}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">报名工种:</div>
                                <div class="value">{{examInfo.occupationName}}</div>
                            </div>
                            <div class="personInifo-wrap" v-if="examInfo.trainLevel">
                                <div class="label">级&emsp;&emsp;别:</div>
                                <div class="value">{{examInfo.trainLevel}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">报名时间:</div>
                                <div class="value">{{examInfo.createTime}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">报名方式:</div>
                                <div class="value">{{$setDictionary('CT_JOIN_TYPE', examInfo.joinType)}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">审核状态:</div>
                                <div class="value">{{$setDictionary('CT_USER_AUDIT_STATE', examInfo.auditState)}}</div>
                            </div>
                        </div>
                        <h3 class="title">个人信息</h3>
                        <div class="examInfo">
                            <div class="personInifo-wrap" v-for="item in personInfoList" :value="item.value" :key="item.attributeId">
                                <template v-if="!item.canModify">
                                    <div class="label">{{item.label}}:</div>
                                    <div class="value">{{item.value}}</div>
                                </template>
                                <template v-else>
                                    <!--文本输入-->
                                    <span v-if="item.attributeType == 1" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-input
                                            style="width: 100%;"
                                            size="small"
                                            v-model="item.value"
                                            :placeholder="'请输入'+item.label">
                                          </el-input>
                                        </div>
                                    </span>
                                    <!--下拉-->
                                    <span v-if="item.attributeType == 3" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-select
                                              style="width: 100%;"
                                            size="small"
                                            v-model="item.value"
                                            :placeholder="'请选择'+item.label">
                                            <el-option
                                              v-for="itemInner in item.options"
                                              :key="itemInner.value"
                                              :label="itemInner.label"
                                              :value="itemInner.value">
                                            </el-option>
                                          </el-select>
                                        </div>
                                    </span>
                                    <!--日期-->
                                    <span v-if="item.attributeType == 4" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-date-picker
                                              style="width: 100%;"
                                            type="date"
                                            v-model="item.value"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            :placeholder="'请选择'+item.label"
                                            size="small"
                                          >
                                          </el-date-picker>
                                        </div>
                                    </span>
                                    <!--行政区划-省市区-->
                                    <span v-if="item.attributeType == 5" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-cascader
                                              style="width: 100%;"
                                            clearable
                                            filterable
                                            v-model="item.value"
                                            :options="areatreeList"
                                            :placeholder="'请选择'+item.label"
                                            :props="propsarea"
                                            :append-to-body="false"
                                            size="small"
                                          ></el-cascader>
                                        </div>
                                    </span>
                                    <!--整数-->
                                    <span v-if="item.attributeType == 6" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-input-number
                                              style="width: 100%;"
                                            v-model="item.value"
                                            :step="1"
                                            :precision="0"
                                            :controls="false"
                                            :placeholder="'请输入'+item.label"
                                            :min="0"
                                            size="small">
                                          </el-input-number>
                                        </div>
                                    </span>
                                    <!--保留三位小数-->
                                    <span v-if="item.attributeType == 7" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-input-number
                                              style="width: 100%;"
                                            v-model="item.value"
                                            :step="1"
                                            :precision="3"
                                            :controls="false"
                                            :placeholder="'请输入'+item.label"
                                            :min="0"
                                            size="small">
                                          </el-input-number>
                                        </div>
                                    </span>
                                    <!--申报条件-->

                                    <span v-if="item.attributeType == 8" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-select
                                              style="width: 100%;"
                                                  size="small"
                                                  v-model="item.value"
                                              popper-class="my-select"
                                              @focus="setOptionWidth"
                                                  :placeholder="'请选择'+item.label">
                                            <el-option
                                                    v-for="itemInner in declarationConditions"
                                                    :key="itemInner.conditionCode"
                                                    :label="itemInner.description"
                                                    :style="{width: selectOptionWidth, 'min-width': '350px'}"
                                                    :value="itemInner.conditionCode">
                                            </el-option>
                                          </el-select>
                                        </div>
                                    </span>

                                    <!--身份证号码-->
                                    <span v-if="item.attributeType == 9" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-input
                                              style="width: 100%;"
                                              size="small"
                                              v-model="item.value"
                                              :placeholder="'请输入'+item.label">
                                          </el-input>
                                        </div>
                                    </span>
                                    <!--行政区划 - 省-->
                                    <span v-if="item.attributeType == 10" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-cascader
                                              style="width: 100%;"
                                              clearable
                                              filterable
                                              v-model="item.value"
                                              :options="areatreeList3"
                                              :placeholder="'请选择'+item.label"
                                              :props="propsarea"
                                              :append-to-body="false"
                                              size="small"
                                          ></el-cascader>
                                        </div>
                                    </span>
                                    <!--行政区划 - 省市-->
                                    <span v-if="item.attributeType == 11" class="attrBox">
                                        <div class="label">{{item.label}}:</div>
                                        <div class="value">
                                          <el-cascader
                                              style="width: 100%;"
                                              clearable
                                              filterable
                                              v-model="item.value"
                                              :options="areatreeList2"
                                              :placeholder="'请选择'+item.label"
                                              :props="propsarea"
                                              :append-to-body="false"
                                              size="small"
                                          ></el-cascader>
                                        </div>
                                    </span>
                                </template>

                            </div>
                        </div>
                    </div>
                    <div class="framePage-body-inner-item">
                        <h3 class="title">材料信息</h3>
                        <div class="materialInfo examInfo">
                            <span  v-for="(item,index) in materialInfoList" :key="item.attributeId">
                                <!--aaa{{item.attributeId}}ccc-->
                                <div class="materialInfo-item">
                                    <div class="img-wrap">
                                        <el-image
                                            v-if="item.value"
                                            style="width: auto; height: 100%"
                                            :src="item.value"
                                            :preview-src-list="[item.value]">
                                        </el-image>
                                        <span v-else style="margin-top: 55px;">暂无图片</span>
                                    </div>
                                    <div class="text">
                                        
                                        <!-- v-if="item.canModify" -->
                                        <el-upload
                                            style="width: 80px;height: 30px;margin: 0 auto;"
                                            :on-change="(file,fileList)=>handlebusinessLicense2(file,fileList,index,item.attributeId,(item.attributeId=='-4'||item.attributeId=='-5')?'2':'1')"

                                            :before-upload="$beforeAvatarUpload"
                                            :http-request="$requestMine"
                                            :show-file-list="false"
                                            class="img-el-upload"
                                            action
                                            accept="image/png, image/gif, image/jpg, image/jpeg"
                                        >
                                            <el-button type="primary" size="mini" style="width: 80px;height: 30px;">上传图片</el-button>
                                            <!--<img :src="item.value|| require('@/assets/develop.png')" alt="">-->
                                        </el-upload>
                                        {{item.label}}
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="btn-group">
                  <el-button type="primary" @click="$router.back()" round>返回</el-button>
                  <el-button type="primary" round @click="updateSubmit()">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"studentUpdate",
        data(){
            return{
                selectOptionWidth:'',
              noPassDialogShow: false,
                // 学员信息（考试信息）
                examInfo:{},
                // 学员信息 （个人信息）
                personInfoList:[],
                // 学员信息（材料信息）
                materialInfoList:[],
                // 培训任务id（路由传参而来）
                projectId:"",
                channelId:null,
                contactUser:null,
                // 学员id（路由传参而来）
                userId:"",
                // 从哪个页面来的 （路由传参）
                origin:"",


                // 行政区划 - 下拉数据 - 省市区
                areatreeList: [],
                // 行政区划 - 下拉数据 - 省市
                areatreeList2: [],
                // 行政区划 - 下拉数据 - 省
                areatreeList3: [],
                // 行政区划 - 规定字段
                propsarea: {
                    value: "value",
                    label: "label",
                    emitPath: false,
                    // checkStrictly: true,
                },
                // 申报条件 - 下拉数据
                declarationConditions: [],

                isIdChange: false//身份证照片是否有改动
            }
        },
        methods:{
            setOptionWidth(event) {
// 下拉框弹出时，设置弹框的宽度
                this.$nextTick(() => {
                    this.selectOptionWidth = event.srcElement.offsetWidth + 'px'
                })
            },
            // 编辑提交
            updateSubmit(){
                // console.log(this.personInfoList,this.materialInfoList)
                // 校验
                try {
                    // 身份证正则
                    let regSFZ = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/

                    this.personInfoList.forEach((e,i)=>{
                        if(e.canModify&&e.requiredField&&!e.value){
                            this.$message.error(e.label+'不能为空')
                            throw Error();
                        }
                        // 如果是身份证号码
                        if(e.canModify&&e.requiredField&&e.value&&e.attributeType == 9&&!regSFZ.test(e.value)){
                            this.$message.error(e.label+'的格式不正确')
                            throw Error();
                        }
                    })
                    // console.log(this.materialInfoList)
                    this.materialInfoList.forEach((e,i)=>{
                        if(e.canModify&&e.requiredField&&!e.value){
                            this.$message.error(e.label+'不能为空')
                            throw Error();
                        }
                    })
                } catch (error) {
                    return
                }
                //是否修改了身份证照片
                if(this.isIdChange){
                    this.$confirm("您现在正在重新上传学生的身份证照片，请确保其准确", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            // 数据提交
                            this.dataSubmit()
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "已取消提交",
                            });
                        });
                    
                }else{
                    // 数据提交
                    this.dataSubmit()
                }
                
            },
            // 数据提交
            dataSubmit(){
                // 数据传参赋值
                let params = {
                    projectId: this.projectId,
                    userId: this.userId,
                    attributes: []
                }
                this.personInfoList.forEach((e,i)=>{
                    if(e.canModify){
                        let item = {
                            attributeId: e.attributeId,
                            attributeValue: ''
                        }
                        if(e.attributeValue&&e.attributeType == 2){
                            item.attributeValue = e.fileKey
                        }else if(e.value&&e.attributeType == 3){
                            item.attributeValue = e.options.find(item => item.value == e.value).label
                        }else if(e.value&&e.attributeType == 7){
                            item.attributeValue = parseFloat(e.value)
                        }else if(e.value&&e.attributeType != 3&&e.attributeType != 7&&e.attributeType != 2){
                            item.attributeValue = e.value
                        }

                        params.attributes.push(item)
                    }
                })
                this.materialInfoList.forEach((e,i)=>{
                    // if(e.canModify){
                        let item = {
                            attributeId: e.attributeId,
                            attributeValue: ''
                        }
                        if(e.fileKey){
                            item.attributeValue = e.fileKey||''
                            // item.fileKey = e.fileKey||''
                        }
                        params.attributes.push(item)
                    // }
                })
                console.log('params',JSON.stringify(params))
                this.$post("/biz/apply/user/modifyUser",params,3000,true,6).then((ret) => {
                    if(ret.status == 0){

                        this.$message.success('修改学员成功！')
                        this.$router.back()
                    }
                });
            },
            // 行政区划 - 下拉数据
            getareatree() {
                this.$post("/sys/area/treeLabel").then((ret) => {
                    //省市县
                    this.areatreeList = ret.data;
                    // 省市
                    this.areatreeList2 = []
                    let list2 = []
                    ret.data.forEach((e,i)=>{
                        let item = {
                            value: e.value,
                            label: e.label,
                            children: []
                        }
                        if(e.children&&e.children.length>0){
                            e.children.forEach((eInner,inner)=>{
                                let itemInner = {
                                    value: eInner.value,
                                    label: eInner.label
                                }
                                item.children.push(itemInner)
                            })
                        }else{
                            item.children = null
                        }

                        list2.push(item)
                    })
                    this.areatreeList2 = list2
                    // 省
                    this.areatreeList3 = []
                    let list3 = []
                    ret.data.forEach((e,i)=>{
                        let item = {
                            value: e.value,
                            label: e.label,
                        }
                        list3.push(item)
                    })
                    this.areatreeList3 = list3
                });
            },
            // 参数下拉数据获取
            selectGetList(keyName){
                const listData = this.$setDictionary(keyName, "list");
                let list = []
                for (const key in listData) {
                    list.push({
                        value: key,
                        label: listData[key],
                    });
                }
                return list
            },
            // 申报条件下拉数据获取
            getDeclarationConditions(){
                this.$post("/biz/apply/user/selectDeclarationCondition",{projectId: this.projectId},3000,true,6).then((ret) => {
                    this.declarationConditions = ret.data;
                });
            },

            // 上传
            handlebusinessLicense2(res, fileList, index,attributeId,type=1) {
                console.log(index,this.materialInfoList[index])
                const isLt10M = res.size / 1024 / 1024 < 10;
                if (!isLt10M) {
                    this.$message.error("上传图片大小不能超过 10MB!");
                } else if (
                    ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
                        res.raw.type
                    ) === -1
                ) {
                    this.$message.error("请上传JPG/PNG/JPEG格式文件");
                    return false;
                } else {
                  if(type==1){
                    this.uploadFetch(res.raw, index,attributeId);
                  }else if(type==2){
                    this.uploadFetch2(res.raw,index,attributeId);
                  }
                }
            },
            uploadFetch(file, index,attributeId) {
                const fileType = file.name.slice(
                    file.name.lastIndexOf(".") + 1,
                    file.name.length
                );
                let formData = new FormData();
                formData.append("file", file);
                formData.append("fileType", fileType);
                formData.append("folder ", "LECTURER");
                formData.append("attributeId", attributeId);
                const that = this;
                this.$Postformat("/sys/upload/uploadAttribute", formData).then((ret) => {
                    that.materialInfoList[index].value = ret.data.fileUrl || "";
                    that.materialInfoList[index].fileKey = ret.data.fileKey || "";
                });
            },
            // 身份证正反面上传
            uploadFetch2(file, index,attributeId) {
                const fileType = file.name.slice(
                    file.name.lastIndexOf(".") + 1,
                    file.name.length
                );
                let formData = new FormData();
                formData.append("file", file);
                formData.append("fileType", fileType);
                formData.append("folder ", "LECTURER");
                const that = this;
                this.$Postformat("/sys/upload", formData).then((ret) => {
                    that.materialInfoList[index].value = ret.data.fileURL || "";
                    that.materialInfoList[index].fileKey = ret.data.fileKey || "";
                    this.isIdChange = true
                });
            },
            initNextProperties() {
              this.projectId=this.$route.query.projectId;
              this.projectName=this.$route.query.projectName;
              this.userId=this.$route.query.userId;
              this.contactUser=this.$route.query.contactUser;
              this.type = this.$route.query.type;
            },
            // 初始化数据
            initData(){
                // 必须先赋值路由参数
                this.origin=this.$route.query.origin;

                this.searchForm =this.$route.query.searchForm?JSON.parse(this.$route.query.searchForm):null;
                // 获取学员详情信息
                this.getExamInfo();
                // 获取个人信息
                this.getPersonInfo();
                // 获取学员材料信息
                this.getMaterialInfo();
                // // 获取不通过理由列表
                // this.getNotPassList()
            },
            // 获取学员详情信息（考试信息）
            getExamInfo(){
                const {projectId,userId,}=this;
                this.$post("/biz/ct/user/ctProjectUserInfo",{
                    projectId,
                    userId
                }).then(res=>{
                    this.examInfo = res.data;
                    this.generatorAuditMsg();
                }).catch(e=>{
                    console.error("获取学员考试信息出错",e);
                });
            },
            generatorAuditMsg() {
              let dt = new Date(Date.parse(this.examInfo.createTime));
              let fullYear = dt.getFullYear();
              let fullMonth = dt.getMonth() + 1;
              let fullDate = dt.getDate();
              this.message = this.examInfo.userName + "学员，您于" + fullYear + "-"
                  + fullMonth + "-" + fullDate + "报名的" + this.examInfo.projectName + "，" + (this.auditReason || "")
                  + "，请登录平台修改相应的信息。"
            },
            // 获取学员详情信息（个人信息）
            getPersonInfo(){
                const {projectId,userId}=this;
                this.$post("/biz/ct/user/info",{
                    projectId,
                    userId
                }).then(res=>{
                    this.personInfoList=res.data || [];
                    // 初始化编辑
                    this.personInfoList.forEach((e,i)=>{
                        if(e.canModify){
                            if(e.attributeType == 3){
                                // 如果是下拉
                                e.options = this.selectGetList(e.dictionaryKey)
                                e.value = e.options.find(item => item.label == e.value).value
                            }
                        }
                    })
                    // console.log(this.personInfoList)

                }).catch(e=>{
                    console.error("获取学员个人信息出错",e);
                });
            },
            // 获取学员详情信息（材料信息）
            getMaterialInfo(){
                let _this = this
                const {projectId,userId}=this;
                this.$post("/biz/ct/user/material",{
                    projectId,
                    userId
                }).then(res=>{
                    _this.materialInfoList = []
                    if(res.data.length>0){
                        res.data.forEach((e,i)=>{
                            let item = {
                                attributeId: e.attributeId,
                                attributeType: e.attributeType,
                                canModify: e.canModify,
                                fileKey: e.fileKey||'',
                                label: e.label,
                                requiredField: e.requiredField,
                                value: e.value||'',
                            }
                            _this.materialInfoList.push(item)
                        })
                    }
                    // this.materialInfoList=res.data || [];
                }).catch(e=>{
                    console.error("获取学员材料信息出错",e);
                });
            },
        },
        created(){
            this.initNextProperties();

            this.initData();
            this.getareatree();
            this.getDeclarationConditions();
        },
    }
</script>
<style>
.my-select .el-select-dropdown__item {
    overflow: visible;
    display: block;
    min-height: 34px;
    height: auto;
    line-height: 20px;
    padding: 7px 20px;
    word-break:break-all;
    word-wrap:break-word;
    white-space: inherit;

}
</style>
<style lang="less" scoped>
/deep/.el-input-number.is-without-controls .el-input__inner{
    text-align: left;
}
    .templateList{
        .framePage-body-inner{
            display: flex;
            .framePage-body-inner-item{
                width: 40%;
                min-width: 25rem;
                .title{
                    position: relative;
                    padding-left: 1.25rem;
                    line-height: 1.17rem;
                    margin: 20px 0;
                    &::after{
                        content:"";
                        display:block;
                        width: 0.525rem;
                        height: 1.17rem;
                        position: absolute;
                        left: 0;
                        top: 0%;
                        background-color: #5C6BE8;
                    }
                }
                .examInfo{
                    padding-left:40px;
                    .personInifo-wrap{
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        margin: 10px 0;
                        border: 1px solid #DCDFE6;
                        padding: 5px 10px;
                        border-radius: 4px;
                        width: 100%;
                        .attrBox{
                            width: 100%;
                        }
                        .label{
                            width: 6rem;
                            border-right: 1px solid #DCDFE6;
                            padding-right: 10px;
                            flex-shrink: 0;
                        }
                        .value{
                            margin-left:20px;
                            color:#909399;
                            min-width: calc(100% - 6rem - 20px);
                        }
                    }
                }
                &:nth-child(2){
                    margin-left:20px;
                    min-width: 43rem;
                }
                .materialInfo{
                    display: flex;
                    flex-wrap: wrap;
                    .materialInfo-item{
                        margin: 10px;
                        cursor: pointer;
                        .img-wrap{
                          text-align: center;
                            width: 17.625rem;
                            height: 8.5rem;
                            border: 1px dashed #c0ccda;
                            border-radius: 4px;
                            overflow: hidden;
                            img{
                                display: block;
                                width: auto;
                                height: 100%;
                                margin: 0 auto;
                            }
                        }
                        .text{
                            text-align: center;
                            margin-top: 10px;
                            /deep/ .el-upload{
                                border-width: 0!important;
                                width: 80px!important;
                                height: 30px!important;
                            }
                        }
                        .img-2c-wrap{
                            width: 12rem;
                            height: 16rem;
                            border: 1px dashed #c0ccda;
                            border-radius: 4px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .btn-group{
            margin-top: 20px;
            padding-left: 12.5rem;
            .el-button{
                background-color: #5C6BE8;
                border: none;
                &:hover{
                    background-color: #6875ee;
                }
            }
        }
        .previewImg{
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 3000;
            display: flex;
            align-items: center;
            padding: 40px;
            box-sizing: border-box;
            .image{
                display: block;
                max-height: 100%;
                height: auto;
                max-width: 100%;
                width: auto;
                margin: 0 auto;
            }
        }
        .fade-enter-active, .fade-leave-active {
            transition: opacity .2s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      background-color: transparent;
    }
    .docsDialog .el-form-item {
      margin-bottom: 5px;
    }
    .attrBox{
      display: flex;
      justify-content: flex-start;
      .label{
        display: flex;
        align-items: center;
      }
      /deep/.el-input-number .el-input__inner{
        text-align: left;
      }
    }
</style>
